import React, { useState, useEffect } from 'react'
import AWS from 'aws-sdk'
import { AWS_CONFIG } from './config'

// AWS configuration
AWS.config.update({
  accessKeyId: 'AKIAXYKJRMO4U6R77BUQ',
  secretAccessKey: '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',
  region: AWS_CONFIG.region,
})

const lambda = new AWS.Lambda()

function App() {
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hello! How can I help you today?' },
  ])
  const [userMessage, setUserMessage] = useState('')
  const [hasSentInitialInstruction, setHasSentInitialInstruction] = useState(
    false,
  )

  // The hidden instruction message
  const initialInstruction =
    'Act as a residential development planning officer.  Try the answer the customer with a yes/no response. Ask questions to resolve any uncertainties. Ask all questions needed to resolve uncertainties.  If not provided ask for the property address.  Once an answer has been reached provide details in what forms the customer needs to complete for the development application if any. Only respond as if it was a spoken conversation.  Do not write actions such as Taps on computer.'

  const handleSendMessage = async () => {
    if (!userMessage.trim()) return

    let modifiedUserMessage = userMessage

    if (!hasSentInitialInstruction) {
      // Prepend the hidden instruction to the first user message sent to the backend
      modifiedUserMessage = `${initialInstruction} ${userMessage}`
      setHasSentInitialInstruction(true)
    }

    const newMessages = [...messages, { sender: 'user', text: userMessage }]
    setMessages(newMessages)
    console.log('new messages', newMessages)
    setUserMessage('')

    try {
      // Build conversation history including the modified user message
      const conversationHistory = newMessages
        .map((msg) => `${msg.sender}: ${msg.text}`)
        .join('\n')

      const params = {
        FunctionName: AWS_CONFIG.lambdaFunctionName,
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
          message: conversationHistory.replace(
            userMessage,
            modifiedUserMessage,
          ),
        }),
      }

      const result = await lambda.invoke(params).promise()
      const responsePayload = JSON.parse(result.Payload)
      const chatbotResponse = JSON.parse(responsePayload.body).response

      setMessages([...newMessages, { sender: 'bot', text: chatbotResponse }])
    } catch (error) {
      console.error('Error invoking Lambda function:', error)
      setMessages([
        ...newMessages,
        {
          sender: 'bot',
          text: 'Sorry, something went wrong. Please try again.',
        },
      ])
    } finally {
      setUserMessage('')
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage()
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault()
      e.returnValue =
        'Are you sure you want to leave? Your chat history will be lost.'
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return (
    <div>
      <div className="header">
        <h1>Welcome to the Sunshine Coast</h1>
      </div>
      <div className="container">
        <img
          src="https://www.betterremovalistssunshinecoast.com.au/wp-content/uploads/2019/09/sc-city.webp"
          alt="Static Image"
          className="background-image"
        />

        <div className="chatbot-flyout open">
          <div className="chatbot-content">
            <div className="chat-container">
              <div className="chat-header">
                <h3>Hyperplanner AI</h3>
              </div>
              <div className="chat-body">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${
                      message.sender === 'bot' ? 'bot-message' : 'user-message'
                    }`}
                  >
                    <p>{message.text}</p>
                  </div>
                ))}
              </div>
              <div className="chat-footer">
                <input
                  type="text"
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Type a message..."
                />
                <button onClick={handleSendMessage}>
                  <i className="fa fa-paper-plane"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
